import React, { useState } from "react";
import { images } from "./ImageData";
import "./Home.css";

function Home() {
  // 검색어를 관리할 상태 변수
  const [searchTerm, setSearchTerm] = useState("");

  // 검색어를 입력할 때마다 호출될 함수
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // 검색 기능을 적용한 이미지 리스트 생성
  const filteredImages = images.filter((image) =>
    image.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="Wrapper">
      <div className="title">테이크파이브 이미지 저장소</div>
      {/* 검색 바 추가 */}
      <input
        type="text"
        placeholder="이미지 검색..."
        value={searchTerm}
        onChange={handleSearch}
        className="seachbar"
      />
      <div className="image-container">
        {filteredImages.map((image) => (
          <div key={image.id} className="image-card">
            <img src={image.src} alt={image.alt} />
            <p className="desc">{image.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;
